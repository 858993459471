// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .connectWalletButton {
    font-size: 20px;
    color: #6344df;
    background-color: #FFFFFF;
    border: 2px solid #6344df;
    border-radius: 8px;
    padding: 5px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-right: 20px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  .connectWalletButton:hover {
    background-color: #6344df;
    color: #FFFFFF;
  }

  @media (max-width: 600px){
    .connectWalletButton {
        font-size: 20px;
        color: #6344df;
        background-color: #FFFFFF;
        border: 2px solid #6344df;
        border-radius: 8px;
        padding: 5px 20px;
        transition: all 0.3s ease;
        cursor: pointer;
        margin-right: 20px;
      }
      
      .connectWalletButton:hover {
        background-color: #6344df;
        color: #FFFFFF;
      }
  }

  @media (max-width: 480px){
    .connectWalletButton {
        font-size: 16px;
        color: #6344df;
        background-color: #FFFFFF;
        border: 2px solid #6344df;
        border-radius: 8px;
        padding: 2px 10px;
        transition: all 0.3s ease;
        cursor: pointer;
        margin-right: 20px;
      }
      
      .connectWalletButton:hover {
        background-color: #6344df;
        color: #FFFFFF;
      }
  }`, "",{"version":3,"sources":["webpack://./src/css/navigation/metamaskButton.css"],"names":[],"mappings":"CAAC;IACG,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,0CAA0C;EAC5C;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE;QACI,eAAe;QACf,cAAc;QACd,yBAAyB;QACzB,yBAAyB;QACzB,kBAAkB;QAClB,iBAAiB;QACjB,yBAAyB;QACzB,eAAe;QACf,kBAAkB;MACpB;;MAEA;QACE,yBAAyB;QACzB,cAAc;MAChB;EACJ;;EAEA;IACE;QACI,eAAe;QACf,cAAc;QACd,yBAAyB;QACzB,yBAAyB;QACzB,kBAAkB;QAClB,iBAAiB;QACjB,yBAAyB;QACzB,eAAe;QACf,kBAAkB;MACpB;;MAEA;QACE,yBAAyB;QACzB,cAAc;MAChB;EACJ","sourcesContent":[" .connectWalletButton {\n    font-size: 20px;\n    color: #6344df;\n    background-color: #FFFFFF;\n    border: 2px solid #6344df;\n    border-radius: 8px;\n    padding: 5px 20px;\n    transition: all 0.3s ease;\n    cursor: pointer;\n    margin-right: 20px;\n    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);\n  }\n  \n  .connectWalletButton:hover {\n    background-color: #6344df;\n    color: #FFFFFF;\n  }\n\n  @media (max-width: 600px){\n    .connectWalletButton {\n        font-size: 20px;\n        color: #6344df;\n        background-color: #FFFFFF;\n        border: 2px solid #6344df;\n        border-radius: 8px;\n        padding: 5px 20px;\n        transition: all 0.3s ease;\n        cursor: pointer;\n        margin-right: 20px;\n      }\n      \n      .connectWalletButton:hover {\n        background-color: #6344df;\n        color: #FFFFFF;\n      }\n  }\n\n  @media (max-width: 480px){\n    .connectWalletButton {\n        font-size: 16px;\n        color: #6344df;\n        background-color: #FFFFFF;\n        border: 2px solid #6344df;\n        border-radius: 8px;\n        padding: 2px 10px;\n        transition: all 0.3s ease;\n        cursor: pointer;\n        margin-right: 20px;\n      }\n      \n      .connectWalletButton:hover {\n        background-color: #6344df;\n        color: #FFFFFF;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
